import { Injectable, Injector        } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { SidebarRouteExtended               } from "@shared/models";
import { UserService, SessionStorageService } from "@shared/services";

import { TimeTrackingSidebarItems } from "../shared/sidebar/time-tracking-sidebar-items";
import { InvoicesSidebarItems     } from "../shared/sidebar/invoices-sidebar-items";

@Injectable({
  providedIn: 'root'
})
export class SidebarStorageService {
  private navItemsSource = new BehaviorSubject<SidebarRouteExtended[]>(null);
  navItems = this.navItemsSource.asObservable();

  private approvedReportsCountSource              = new BehaviorSubject<number>(0);
  private openActivityReportsCountSource          = new BehaviorSubject<number>(0);
  private clarificationReportsCountSource         = new BehaviorSubject<number>(0);
  private failedExportActivityReportsCountSource  = new BehaviorSubject<number>(0);

  private activePhotoDocumentsCountSource         = new BehaviorSubject<number>(0);
  private unconfirmedEBSCountSource               = new BehaviorSubject<number>(0);
  private expiringSoonAssignmentsCountSource      = new BehaviorSubject<number>(0);
  private awaitingMileageMoneyReportsCountSource  = new BehaviorSubject<number>(0);

  private approvedVacationRequestsCountSource     = new BehaviorSubject<number>(0);
  private awaitingVacationRequestsCountSource     = new BehaviorSubject<number>(0);
  private rejectedVacationRequestsCountSource     = new BehaviorSubject<number>(0);
  private failedExportVacationRequestsCountSource = new BehaviorSubject<number>(0);

  private activeInvoicesCountSource               = new BehaviorSubject<number>(0);
  private archivedInvoicesCountSource             = new BehaviorSubject<number>(0);

  approvedReportsCount              = this.approvedReportsCountSource.asObservable();
  openActivityReportsCount          = this.openActivityReportsCountSource.asObservable();
  clarificationReportsCount         = this.clarificationReportsCountSource.asObservable();
  failedExportActivityReportsCount  = this.failedExportActivityReportsCountSource.asObservable();

  activePhotoDocumentsCount         = this.activePhotoDocumentsCountSource.asObservable();
  unconfirmedEBSCount               = this.unconfirmedEBSCountSource.asObservable();
  expiringSoonAssignmentsCount      = this.expiringSoonAssignmentsCountSource.asObservable();
  awaitingMileageMoneyReportsCount  = this.awaitingMileageMoneyReportsCountSource.asObservable();

  approvedVacationRequestsCount     = this.approvedVacationRequestsCountSource.asObservable();
  awaitingVacationRequestsCount     = this.awaitingVacationRequestsCountSource.asObservable();
  rejectedVacationRequestsCount     = this.rejectedVacationRequestsCountSource.asObservable();
  failedExportVacationRequestsCount = this.failedExportVacationRequestsCountSource.asObservable();

  archivedInvoicesCount             = this.archivedInvoicesCountSource.asObservable();
  activeInvoicesCount               = this.activeInvoicesCountSource.asObservable();

  private sidebarStateSource = new BehaviorSubject<string>(null);
  sidebarState = this.sidebarStateSource.asObservable();

  constructor (
    private injector:    Injector,
    private userService: UserService
  ) { }

  changeApprovedReportsCount(             count: number): void { this.approvedReportsCountSource.next(count);              }
  changeOpenActivityReportsCount(         count: number): void { this.openActivityReportsCountSource.next(count);          }
  changeClarificationReportsCount(        count: number): void { this.clarificationReportsCountSource.next(count);         }
  changeFailedExportActivityReportsCount( count: number): void { this.failedExportActivityReportsCountSource.next(count);  }

  changeActivePhotoDocumentsCount(        count: number): void { this.activePhotoDocumentsCountSource.next(count);         }
  changeUnconfirmedEBSCount(              count: number): void { this.unconfirmedEBSCountSource.next(count);               }
  changeExpiringSoonAssignmentsCount(     count: number): void { this.expiringSoonAssignmentsCountSource.next(count);      }
  changeAwaitingMileageMoneyReportsCount( count: number): void { this.awaitingMileageMoneyReportsCountSource.next(count);  }

  changeApprovedVacationRequestsCount(    count: number): void { this.approvedVacationRequestsCountSource.next(count);     }
  changeAwaitingVacationRequestsCount(    count: number): void { this.awaitingVacationRequestsCountSource.next(count);     }
  changeRejectedVacationRequestsCount(    count: number): void { this.rejectedVacationRequestsCountSource.next(count);     }
  changeFailedExportVacationRequestsCount(count: number): void { this.failedExportVacationRequestsCountSource.next(count); }

  changeArchivedInvoicesCount(            count: number): void { this.archivedInvoicesCountSource.next(count);             }
  changeActiveInvoicesCount(              count: number): void { this.activeInvoicesCountSource.next(count);               }

  get approvedReportsCountValue():              number { return this.approvedReportsCountSource.value;              }
  get openActivityReportsCountValue():          number { return this.openActivityReportsCountSource.value;          }
  get clarificationReportsCountValue():         number { return this.clarificationReportsCountSource.value;         }
  get failedExportActivityReportsCountValue():  number { return this.failedExportActivityReportsCountSource.value;  }

  get activePhotoDocumentsCountValue():         number { return this.activePhotoDocumentsCountSource.value;         }
  get unconfirmedEBSCountValue():               number { return this.unconfirmedEBSCountSource.value;               }
  get expiringSoonAssignmentsCountValue():      number { return this.expiringSoonAssignmentsCountSource.value;      }
  get awaitingMileageMoneyReportsCountValue():  number { return this.awaitingMileageMoneyReportsCountSource.value;  }

  get approvedVacationRequestsCountValue():     number { return this.approvedVacationRequestsCountSource.value;     }
  get awaitingVacationRequestsCountValue():     number { return this.awaitingVacationRequestsCountSource.value;     }
  get rejectedVacationRequestsCountValue():     number { return this.rejectedVacationRequestsCountSource.value;     }
  get failedExportVacationRequestsCountValue(): number { return this.failedExportVacationRequestsCountSource.value; }

  get archivedInvoicesCountValue():             number { return this.archivedInvoicesCountSource.value;             }
  get activeInvoicesCountValue():               number { return this.activeInvoicesCountSource.value;               }

  changeNavItems(navItems: SidebarRouteExtended[]): void {
    this.navItemsSource.next(navItems);
  }

  prepareTimeTrackingSidebar(): void {
    let tempNavItems = TimeTrackingSidebarItems.filter(item => this.filterByRole(item))
    .map(item => {
      if (item.children) item.children = item.children.filter(child => this.filterByRole(child)).filter(i => {
        if (!i.betaFlag) return true;
        return this.userService.currentUserValue?.features[i.betaFlag]?.length;
      });
      return item;
    });    
    tempNavItems = this.highlightNewFeatures(tempNavItems);
    this.changeNavItems(tempNavItems);
  }

  private filterByRole(item): boolean {
    if (!item.isCustomer && !item.isInternal) return true;
    else if (item.isCustomer) return item.isCustomer && this.userService.isCustomer;
    else if (item.isInternal) return item.isInternal && this.userService.isInternal;
  }

  private highlightNewFeatures(navItems: SidebarRouteExtended[]): SidebarRouteExtended[] {
    let sessionStorageService = this.injector.get(SessionStorageService);
    let list = navItems.map(i => ({...i}));

    if (this.userService.latestRelease && +(new Date(this.userService.latestRelease.released_at)) < +new Date() + 7*24*60*60*1000 && !sessionStorageService.checkReleasePassed(this.userService.latestRelease)) this.highlightFeature(list, 'releases');
    return list;
  }

  private highlightFeature(list: SidebarRouteExtended[], feature: string): void {
    let nav = list.find(i => i.path === feature);
    if (nav) nav.dot = true;
  }

  prepareInvoicesSidebar(): void {
    this.changeNavItems(InvoicesSidebarItems);
  }

  changeSidebar(app: string = null): void {
    if (!app || app === 'time-tracking') this.prepareTimeTrackingSidebar();
    else if    (app === 'invoices')      this.prepareInvoicesSidebar();
  }

  get sidebarItems(): SidebarRouteExtended[] {
    return this.navItemsSource.value;
  }

  get sidebarItemsSubscribe(): Observable<SidebarRouteExtended[]> {
    return this.navItems;
  }

  get navItemsMapped(): SidebarRouteExtended[] {
    let parent   = this.sidebarItems;
    let children = [...this.sidebarItems.filter(i => i.children).reduce((sum, val) => sum = [...sum, ...val.children], [])];
    return [...parent, ...children];
  }

  changeSidebarState(state: string): void {
    this.sidebarStateSource.next(state);
  }

  toggleSidebarState(): void {
    this.sidebarStateSource.next(this.sidebarStateSource.value === 'mini' ? 'full' : 'mini');
  }

}
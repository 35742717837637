import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { take   } from 'rxjs';

import { ActivityReportExtended, VacationRequestExtended } from '@shared/factories';
import { CustomersNotificationPostModel } from '@shared/models';
import { CustomersNotificationsService, NotificationService, SessionStorageService, VacationRequestService, WorkingPeriodService } from '@shared/services';

import { messages } from "@messages";

interface InputOptionModel {
  value: string;
  label: string;
}

@Component({
  selector:      'details-footer',
  templateUrl: './details-footer.component.html',
  host: { 'class': 'am-flex-column gap-l' }
})
export class DetailsFooterComponent implements OnInit {
  @Input() entry:             ActivityReportExtended | VacationRequestExtended;
  @Input() isCustomer:        boolean;

  @Input() createPDF:         Function;
  @Input() customerExport:    boolean;
  @Input() internalCheck:     boolean;
  @Input() rotationAngle:     number;

  @Input() approve:           boolean;
  @Input() reject:            boolean;
  @Input() rejectSingleClick: boolean;
  @Input() rejectMode:        boolean;
  @Input() rejectPlaceholder: string;
  rejectReason:               string = null;
  rejectComment:              string = null;
  rejectOptions:              InputOptionModel[];

  @Input() create:            boolean;
  @Input() archive:           boolean;
  @Input() delete:            boolean;

  @Input() approveAndClose:   boolean;
  @Input() approveAndNext:    boolean;

  @Input() resolveAndClose:   boolean;
  @Input() resolveAndNext:    boolean;

  @Output() backCallback:            EventEmitter<any> = new EventEmitter();

  @Output() customerExportCallback:  EventEmitter<any> = new EventEmitter();

  @Output() approveCallback:         EventEmitter<any> = new EventEmitter();
  @Output() rejectCallback:          EventEmitter<any> = new EventEmitter();
  @Output() rejectModeCallback:      EventEmitter<any> = new EventEmitter();

  @Output() createCallback:          EventEmitter<any> = new EventEmitter();
  @Output() archiveCallback:         EventEmitter<any> = new EventEmitter();
  @Output() deleteCallback:          EventEmitter<any> = new EventEmitter();

  @Output() approveAndCloseCallback: EventEmitter<any> = new EventEmitter();
  @Output() approveAndNextCallback:  EventEmitter<any> = new EventEmitter();

  @Output() resolveAndCloseCallback: EventEmitter<any> = new EventEmitter();
  @Output() resolveAndNextCallback:  EventEmitter<any> = new EventEmitter();

  approveAndCloseLabel: string;
  approveAndNextLabel:  string;
  rejectLabel:          string;
  constructor(
    private router:                        Router,
    private workingPeriodService:          WorkingPeriodService,
    private vacationRequestService:        VacationRequestService,
    private customersNotificationsService: CustomersNotificationsService,
    private sessionStorageService:         SessionStorageService,
    private notificationService:           NotificationService
  ) { }

  ngOnInit(): void {
    this.prepareRejectOptions();
    this.prepareButtonLabels();
  }

  private prepareButtonLabels(): void {
    if (this.entry instanceof VacationRequestExtended) {
      if (this.isCustomer) {
        this.approveAndCloseLabel = 'Bestätigen & schließen';
        this.approveAndNextLabel  = 'Bestätigen & weiter';
        this.rejectLabel          = 'Nicht bestätigen';
      }
    }
  }

  useCaseNameShort(): string {
    if (this.entry instanceof ActivityReportExtended)  return 'TN';
    if (this.entry instanceof VacationRequestExtended) return 'UA';
  }

  openExportModal(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'ExportCustomerPopup',
      props: {
        assignment_id: this.entry?.assignment?.id,
        clickAccept: async (data: any) => this.exportPDFToCustomer(data),
      }
    });
  }

  private async exportPDFToCustomer(data) {
    this.notificationService.wait();
    let pdf = await this.createPDF();
    pdf && pdf.create().getBase64(base64 => {
      let params: CustomersNotificationPostModel = {
        customers_notification: {
          customers_emails: data.customers.map(c => c.email),
          attachment:      `data:application/pdf;base64,${base64}`,
          email_template:   data.email_template
        }
      };

      if (this.entry instanceof ActivityReportExtended)  params.customers_notification.working_period_id   = this.entry.id;
      if (this.entry instanceof VacationRequestExtended) params.customers_notification.vacation_request_id = this.entry.id;

      if (this.entry instanceof ActivityReportExtended)  params.customers_notification.type = 'working_period';
      if (this.entry instanceof VacationRequestExtended) params.customers_notification.type = 'vacation_request';

      this.customersNotificationsService.sendCustomersNotification(params).pipe(take(1)).subscribe(
        res => this.customerExportCallback.emit(res),
        err => this.notificationService.alert(err)
      );
    });
  }

  internalCheckHandler(): void {
    let call, message;
    this.notificationService.wait();
    if (this.entry instanceof ActivityReportExtended)  call    = this.workingPeriodService.internalCheck(this.entry.id, this.rotationAngle);
    if (this.entry instanceof VacationRequestExtended) call    = this.vacationRequestService.internalCheck(this.entry.id);

    if (this.entry instanceof ActivityReportExtended)  message = messages.activity_reports.details.success.thanksActivityChecked;
    if (this.entry instanceof VacationRequestExtended) message = messages.vacation_requests.details.success.thanksVacationChecked;

    call.pipe(take(1)).subscribe(
      res  => this.notificationService.success(
        message,
        () => this.router.navigateByUrl(this.sessionStorageService.redirectUrl)
      ),
      error => this.notificationService.alert(error.message || error)
    );
  }

  private prepareRejectOptions(): void {
    if (this.entry instanceof ActivityReportExtended) this.rejectOptions = [
      { value: 'Mitarbeiter nicht erschienen',    label: 'Mitarbeiter nicht erschienen'    },
      { value: 'Angegebene Zeiten stimmen nicht', label: 'Angegebene Zeiten stimmen nicht' },
    ];
    if (this.entry instanceof VacationRequestExtended) this.rejectOptions = [
      { value: 'incorrect_dates',       label: 'Falsche Daten'           },
      { value: 'no_vacation_days_left', label: 'Keine Urlaubstage übrig' },
      { value: 'internal_reasons',      label: 'Interne Gründe'          }
    ];
  }

  rejectHandler(): void {
    this.rejectCallback.emit({ reason: this.rejectReason, comment: this.rejectComment });
  }

  archiveHandler(): void {
    if (this.entry instanceof VacationRequestExtended) {
      if (this.entry.awaitingExternalReview) this.rejectReason = 'obsolete';
      if (this.entry.rejectedByExternal)     this.rejectReason = 'rejected_by_external';

      this.archiveCallback.emit({ reason: this.rejectReason });
    }
  }

}

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 18px;
  transition: height 150ms linear;
  font-size: 12px;
}
:host.minimised {
  height: 25px;
}
:host.collapsed {
  height: 175px;
}
@media (max-height: 1000px) {
  :host.collapsed {
    height: 160px;
  }
}
@media (max-height: 850px) {
  :host.collapsed {
    height: 145px;
  }
}
@media (max-width: 1280px) {
  :host.collapsed {
    height: 155px;
  }
}
@media (max-width: 1280px) and (max-height: 1000px) {
  :host.collapsed {
    height: 170px;
  }
}
@media (max-width: 1280px) and (max-height: 850px) {
  :host.collapsed {
    height: 155px;
  }
}
@media (max-width: 768px) {
  :host.collapsed {
    height: 155px;
  }
}
@media (max-width: 450px) {
  :host.collapsed {
    height: 125px;
  }
}
:host.collapsed .ready-to-review-title .web-tooltip {
  display: flex;
}
:host .ready-to-review-title {
  min-height: 25px;
  cursor: pointer;
}
:host .ready-to-review-title .icon-arrow-down,
:host .ready-to-review-title .icon-mail-config {
  display: flex;
}
:host .ready-to-review-title .web-tooltip {
  width: calc(100% - 30px);
  bottom: calc(100% + 10px);
}
:host .ready-to-review-title .web-tooltip .tooltip-title {
  font-size: 12px;
}
:host .ready-to-review-title .icon-mail-config {
  flex: 0 0 24px;
}
:host .ready-to-review-options {
  padding: 0 2px;
}
:host .ready-to-review-options > * {
  min-height: 25px;
  padding-top: 10px;
}
@media (max-height: 1000px) {
  :host .ready-to-review-options > * {
    padding-top: 5px;
  }
}
@media (max-width: 768px) {
  :host .ready-to-review-options > * {
    padding-top: 5px;
  }
}
@media (max-height: 850px) {
  :host .ready-to-review-options > * {
    padding-top: 0;
  }
}
@media (max-width: 450px) {
  :host .ready-to-review-options > * {
    padding-top: 0;
  }
}
:host .ready-to-review-options > *.active {
  font-weight: 500;
}
:host .ready-to-review-options > *.active .option-circle {
  border-color: #0099A8;
  background-color: #0099A8;
}
:host .ready-to-review-options > *:hover .option-circle {
  border-color: #0099A8;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

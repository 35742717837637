import { Component, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { map, SubscriptionLike, take } from 'rxjs';

import { User                                          } from '@shared/factories';
import { PillModel, SidebarRouteExtended, TogglerModel } from '@shared/models';
import { AutoReloadService, NotificationService, SessionStorageService, UserService, WebsocketService } from '@shared/services';

@Component({
  selector:      'basic-pills',
  templateUrl: './basic-pills.component.html',
  styleUrls:  ['./basic-pills.component.sass']
})
export class BasicPillsComponent implements OnInit, OnDestroy {
  readonly SHOW_ENTRIES_LIMIT = 12;

  subscriptions: SubscriptionLike[] = [];

  user:          User;
  title:         string;
  activeTab:     SidebarRouteExtended;
  activeToggler: TogglerModel; 

  entries:       PillModel[];
  singleSelect:  boolean;

  popupPills:    PillModel[];
  showPopup:     boolean;
  search:        string;

  @Output() callback = new EventEmitter<any>();
  constructor( 
    private notificationService:   NotificationService,
    private autoReloadService:     AutoReloadService,
    private websocketService:      WebsocketService,
    private userService:           UserService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.userService.currentUser.subscribe(user => {
      this.user = user;
      if (user.isCustomer) this.title = 'Kundennummer';
      if (user.isInternal) this.title = 'Niederlassung';

      if (user.isCustomer) this.singleSelect = true;

      if (!this.activeTab) this.loadActiveTab();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private loadActiveTab(): void {
    this.sessionStorageService.activeTab.pipe(take(1)).subscribe(tab => {
      this.activeTab = tab;
      this.loadTogglers();
    });
  }

  private loadTogglers(): void {
    this.subscriptions.push(this.sessionStorageService.headerTogglers.subscribe(toggler => {
      if      (this.activeTab.identifier === 'archive')    this.activeToggler = toggler.archive.find(f => f.active);
      else if (this.activeTab.identifier === 'failed-erp') this.activeToggler = toggler.failed_erp.find(f => f.active);
      else this.activeToggler = null;

      if (!this.entries) this.loadPills();
      else this.mapBetaLocations();
    }));
  }

  private loadPills(): void {
    this.subscriptions.push(this.sessionStorageService.pills.pipe(
      map(pills => {
        if (this.user.isCustomer) return this.filterByAccess(pills);
        return pills;
      })
    ).subscribe(pills => {
      if (!this.entries) this.renderEntries(pills);
      else this.entries = pills;
    }));
  }

  private filterByAccess(pills: PillModel[]): PillModel[] {
    let resource, customerCompanies = this.user.customer_companies;
    if (this.sessionStorageService.isTimeTracking)  resource = 'Default';
    else if (this.sessionStorageService.isInvoices) resource = 'CustomerInvoice';

    let valid = customerCompanies.filter(cc => cc.access_rights.find(a => a.resource_name === resource));
    return pills.filter(p => valid.find(v => v.company_number === p.company_number));
  }

  updateDataHandler(): void {
    this.notificationService.wait();
    this.sessionStorageService.changePageNumber(1);
    setTimeout(() => {
      this.autoReloadService.forceAutoReload();
      this.callback.emit();
      this.websocketService.reloadWebSockets(this.entries.filter(e => e.active).map(e => {
        if (this.userService.currentUserValue.isInternal) return e.location;
        if (this.userService.currentUserValue.isCustomer) return e.company_number;
      }));
    });
  }

  getMainMenuPills(): PillModel[] {
    return this.entries.filter(e => e.recent);
  }

  private renderEntries(pills: PillModel[]): void {
    if (pills.length <= 4) pills = this.showAllEntries(pills);
  
    let shown = pills.filter(e => e.recent);
    if (shown.length === 0) pills = this.showFirstFourEntry(pills);

    this.entries = pills;
    this.sessionStorageService.updatePills(this.entries);

    this.mapBetaLocations();
  }

  private mapBetaLocations(): void {
    let betaFlag      = this.activeToggler?.beta || this.activeTab.betaFlag;
    let betaLocations = this.user?.features && this.user.features[betaFlag] || [];

    if (betaFlag) this.entries.forEach(e => { e.disabled = !betaLocations.find(l => +e.location === +l); });
    else this.entries.forEach(e => { e.disabled = false });
  }

  private showAllEntries(pills: PillModel[]): PillModel[] {
    return pills.map(e => {
      e.recent = true;
      return e;
    });
  }

  private showFirstFourEntry(pills: PillModel[]): PillModel[] {
    return pills.map((e, index) => {
      if (index < 4) e.recent = true;
      return e;
    });
  }

  togglePill(entry: PillModel): void {
    if (this.singleSelect) this.toggleSingleSelectPill(entry);
    else if (entry.active) this.disablePill(entry);
    else this.enablePill(entry);
  }

  private toggleSingleSelectPill(entry: PillModel): void {
    if (!entry.active) {
      this.entries.forEach(ent => ent.active = false);
      this.enablePill(entry);
    }
  }

  private enablePill(entry: PillModel, skipUpdate: boolean = null): void {
    if (!entry.active && this.checkLimits(this.entries)) {
      entry.active = true;
      this.sessionStorageService.updatePills(this.entries);
      if (!skipUpdate) this.updateDataHandler();
    }
  }

  private disablePill(entry: PillModel): void {
    entry.active = false;
    if (this.singleSelect) this.getMainMenuPills()[0].active = true;
    this.sessionStorageService.updatePills(this.entries);
    this.updateDataHandler();
  }

  removePill(e: Event, entry: PillModel): void {
    e.preventDefault();
    e.stopPropagation();
    entry.recent = false;
    this.disablePill(entry);
  }


  allPillsAreActive(entries: PillModel[]): boolean {
    return !entries.find(e => !e.active);
  }

  noPillsAreActive(entries: PillModel[]): boolean {
    return !entries.find(e => e.active);
  }

  pillsActiveLength(): number {
    return this.entries.filter(e => e.active).length ? this.entries.filter(e => e.active).length : this.entries.length;
  }


  openPopup(): void {
    this.showPopup = true;
    this.popupPills = JSON.parse(JSON.stringify(this.entries));
  }

  closePopup(): void {
    let oldTemp = this.entries.filter(  e => e.active).map(e => e.company_number || e.location).join(',');
    let newTemp = this.popupPills.filter(e => e.active).map(e => e.company_number || e.location).join(',');

    this.entries = [...this.popupPills];
    this.sessionStorageService.updatePills(this.entries);

    if (oldTemp !== newTemp) this.updateDataHandler();

    this.popupPills = null;
    this.showPopup = false;
  }

  filterEntriesBySearch(): PillModel[] {
    if (this.search) return this.popupPills.filter(l => l.label.toLowerCase().includes(this.search.toLowerCase()));
    return this.popupPills;
  }

  deSelectAll(entries: PillModel[]): void {
    entries.forEach(e => e.active = false);
  }

  selectAll(entries: PillModel[]): void {
    entries.forEach(e => e.active = true);
  }

  togglePopupEntry(e: Event, entry: PillModel): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.singleSelect) this.toggleSingleSelectCheckbox(entry);
    else this.toggleCheckbox(entry);
  }

  private toggleSingleSelectCheckbox(entry: PillModel): void {
    this.popupPills.forEach(ent => ent.active = false);
    let pill = this.popupPills.find(e => e.company_number === entry.company_number);
    pill.active = true;
  }

  private toggleCheckbox(entry: PillModel): void {
    if (this.checkLimits(this.popupPills)) entry.active = !entry.active;
    else entry.active = false;

    this.handleShownEntries(entry);
  }

  private checkLimits(entries: PillModel[]): boolean {
    let activeCount   = entries.filter(p =>  p.active).length;
    let unActiveCount = entries.filter(p => !p.active).length;
    if (activeCount < 30 || unActiveCount <= 1) return true;
    this.notificationService.alert('Maximal 30 Niederlassungen können Sie manuell auswählen.');
    return false;
  }

  get activeCompany(): number {
    return this.singleSelect && this.popupPills?.find(p => p.active)?.company_number;
  }

  private handleShownEntries(entry: PillModel): void {
    if ( entry.active && !entry.recent) this.showActivatedEntry(entry);
    if (!entry.active &&  entry.recent) this.hideDeActivatedEntry(entry);
  }

  private showActivatedEntry(entry: PillModel): void {
    let shown = this.popupPills?.filter(p => p.recent);
    if (shown.length >= this.SHOW_ENTRIES_LIMIT) {
      let unactive = shown.find(p => !p.active);
      if (unactive) unactive.recent = false;
    }
    this.showEntry(this.popupPills, entry);
  }

  private hideDeActivatedEntry(entry: PillModel): void {
    let shown  = this.popupPills?.filter(p => p.recent);
    let active = this.popupPills?.filter(p => p.active);
    if (active.length >= this.SHOW_ENTRIES_LIMIT &&
         shown.length >= this.SHOW_ENTRIES_LIMIT) {
      entry.recent    = false;
      let entryToShow = this.popupPills.find(p => p.active && !p.recent);
      if (entryToShow) this.showEntry(this.popupPills, entryToShow);
    }
  }

  toggleShowEntry(event: Event, entries: PillModel[], entry: PillModel) {
    event.stopPropagation();
    if     (!entry.recent) this.showEntry(entries, entry);
    else if (entry.recent) this.hideEntry(entries, entry);
  }

  private hideEntry(entries: PillModel[], entry: PillModel): void {
    let shown = entries.filter(p => p.recent).length;
    if (shown > 1) entry.recent = false;
  }

  private showEntry(entries: PillModel[], entry: PillModel): void {
    let shown = entries.filter(p => p.recent).length;
    if (shown < this.SHOW_ENTRIES_LIMIT) entry.recent = true;
  }

}

import { Component, Input, Output, EventEmitter, HostBinding, OnInit, HostListener } from '@angular/core';
import { take             } from 'rxjs/operators';

import { UserService, NotificationService, SessionStorageService } from '@shared/services';
import { User                             } from '@shared/factories';

import { messages } from '@messages';

@Component({
  selector:     'sidebar-user-preferences-toggler',
  templateUrl:  'sidebar-user-preferences-toggler.component.html',
  styleUrls: ['./sidebar-user-preferences-toggler.component.sass'],
  host: {'class': 'overflow ready-to-review'}
})
export class SidebarUserPreferencesTogglerComponent implements OnInit {
  @Input() sidebarMinified: boolean;
  @HostBinding('class.collapsed') @Input() collapsePreview: boolean = false;
  @HostBinding('class.height-100-max')     isInvoice:       boolean = false;
  @Output() toggleSidebar = new EventEmitter<any>();

  userNotificationValue: string;
  readyToReviewOptions:    { name: string, value: string, app: string }[] = [];
  readyToReviewOptionsAll: { name: string, value: string, app: string }[] = [
    { name: 'Täglich',                               value: 'daily',   app: 'time-tracking' },
    { name: 'Wöchentlich (jeweils Montag morgen)',   value: 'weekly',  app: 'time-tracking' },
    { name: 'Monatlich (am letzten Tag des Monats)', value: 'monthly', app: 'time-tracking' },
    { name: 'Keine Benachrichtigungen',              value: 'off',     app: 'time-tracking' },
    { name: 'an',                                    value: 'on',      app: 'invoices'      },
    { name: 'aus',                                   value: 'off',     app: 'invoices'      },
  ];

  isTimeTracking: boolean;
  currentUser: User;
  @HostListener('document:click', ['$event'])
  preventSidebarClose(event) {
    if (event.target.closest('.sidebartoggler')) this.collapsePreview = false;
  }
  constructor(
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService,
    private userService:           UserService,
  ) { }

  ngOnInit(): void {
    this.userService.currentUser.subscribe(user => {
      this.currentUser = user;
      this.updateValues();
    });
    this.sessionStorageService.activeApp.subscribe(app => {
      this.readyToReviewOptions = this.readyToReviewOptionsAll.filter(o => o.app === app);
      this.isTimeTracking = app === 'time-tracking';
      this.isInvoice      = app === 'invoices';
      this.updateValues();
    });
  }

  private updateValues(): void {
    if (this.currentUser) {
      if (this.isTimeTracking) this.userNotificationValue = this.currentUser.reportsNotification  && this.currentUser.reportsNotification.value;
      if (this.isInvoice)      this.userNotificationValue = this.currentUser.invoicesNotification && this.currentUser.invoicesNotification.value;
    }
  }

  updatePreferences(val: string): void {
    this.notificationService.wait();
    this.userService.updatePreferencesHandler(val, this.isTimeTracking).pipe(take(1)).subscribe(
      res => {
        this.collapsePreview = false;
        this.userService.forceReload();
        this.notificationService.success(messages.sidebar.success.changesSaved)
      },
      err => this.notificationService.alert(err)
    );
  }

  togglePreferences(): void {
    if ($('#portal-sidebar').hasClass('mini-sidebar') && !$('#portal-sidebar').hasClass('mini-sidebar-xs')) {
      this.toggleSidebar.emit();
      this.collapsePreview = true;
    }
    else this.collapsePreview = !this.collapsePreview;
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarRouteExtended } from '@shared/models';
import { collapse } from 'app/animations';

@Component({
  selector:      'sidebar-item',
  templateUrl: './sidebar-item.component.html',
  animations: [ collapse ],
  host: { class: 'overflow-visible' }
})
export class SidebarItemComponent {
  @Input() item:            SidebarRouteExtended;
  @Input() activeTab:       SidebarRouteExtended;
  @Input() sidebarMinified: boolean;
  @Input() getReportsCount: Function;

  @Output() callback = new EventEmitter<any>();
  constructor() {}

  callbackHandler(): void {
    this.callback.emit(this.item);
  }

  isActive(item): boolean {
    if (item?.identifier === 'archive' && this.activeTab.identifier.includes('archive')) return true;
    return this.activeTab?.filter ? this.activeTab?.filter === item?.filter : this.activeTab?.identifier === item?.identifier;
  }

  toggleSection(e, item): void {
    e.stopPropagation();
    item.collapse = !item.collapse;
  }

  childClick(e): void {
    e.stopPropagation();
  }

}
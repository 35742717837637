import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { AuthService } from '@tempton/ngx-msal';

import {
  ActivityReportsService,
  PhotoDocumentService,
  SessionStorageService,
  NotificationService,
  SidebarStorageService,
  UserService,
  AssignmentService,
  ToasterService,
  InvoicesService,
  FailedErpService,
  VacationRequestListService,
  MileageMoneyListService
} from '@shared/services';

import { SidebarRoute, SidebarRouteExtended } from '@shared/models';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit {
  mainNavItems:    SidebarRouteExtended[];
  botomNavItems:   SidebarRouteExtended[];
  activeTab:       SidebarRouteExtended;
  activeApp:       string;
  sidebarMinified: boolean;

  openActivityReportsCount:          number;
  clarificationReportsCount:         number;
  approvedReportsCount:              number;
  failedExportActivityReportsCount:  number;

  activePhotoDocumentsCount:         number;
  unconfirmedEBSCount:               number;
  expiringSoonAssignmentsCount:      number;

  activeInvoicesCount:               number;
  archivedInvoicesCount:             number;

  approvedVacationRequestsCount:     number;
  awaitingVacationRequestsCount:     number;
  rejectedVacationRequestsCount:     number;
  failedExportVacationRequestsCount: number;

  awaitingMileageMoneyReportsCount:  number;

  public getReportsCountFn: Function = this.getReportsCount.bind(this);

  collapsePreview: boolean = false;

  subscriptions:    SubscriptionLike[] = [];
  appSubscriptions: SubscriptionLike[] = [];

  version:    string  = environment.VERSION;
  production: boolean = environment.production;

  debugMode:    boolean;
  debugCounter: number = 0;
  @HostListener('document:click', ['$event'])
  preventSidebarClose(event) {
    if (window.innerWidth < 768) {
      if (event.target.closest('#portal-sidebar')) event.stopPropagation();
      else if (event.target.closest('aside')) this.closeSidebar();
    }
    if (event.target.closest('.sidebartoggler')) {
      this.toggleSidebar();
      this.collapsePreview = false;
    }
  }
  constructor(
    private router:                     Router,
    private authService:                AuthService,
    private assignmentService:          AssignmentService,
    private activityReportsService:     ActivityReportsService,
    private photoDocumentService:       PhotoDocumentService,
    private invoicesService:            InvoicesService,
    private vacationRequestListService: VacationRequestListService,
    private mileageMoneyListService:    MileageMoneyListService,
    private failedErpService:           FailedErpService,
    private sessionStorageService:      SessionStorageService,
    private sidebarStorageService:      SidebarStorageService,
    private notificationService:        NotificationService,
    private toasterService:             ToasterService,
    public  userService:                UserService
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.sidebarStorageService.sidebarItemsSubscribe.subscribe(items => {
      this.mainNavItems  = items.filter(i => !i.bottom);
      this.botomNavItems = items.filter(i =>  i.bottom);
    }));
    this.sessionStorageService.activeApp.subscribe(app => {
      if (this.activeApp !== app) this.resetSubscriptions();
      this.activeApp = app;
    })
    this.subscriptions.push(this.sessionStorageService.activeTab.subscribe(tab => this.activeTab = tab));
    this.subscriptions.push(this.sidebarStorageService.sidebarState.subscribe(status => this.sidebarMinified = status === 'mini'));
    this.prepareAppSubscriptions();
  }

  ngAfterViewInit() {
    const set = () => {
      if (window.innerWidth < 768) this.closeSidebar();
      this.collapsePreview = false;
    };
    $(window).ready(set);
    $(window).on('resize', set);
    $('body').trigger('resize');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
    this.stopAppSubscriptions();
  }

  private prepareAppSubscriptions(): void {
    if  (this.sessionStorageService.isTimeTracking) this.prepareTimeTrackingSubscriptions();
    else if (this.sessionStorageService.isInvoices) this.prepareInvoicesSubscriptions();
  }

  private stopAppSubscriptions(): void {
    this.appSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.appSubscriptions = [];
  }

  resetSubscriptions(): void {
    this.stopAppSubscriptions();
    this.prepareAppSubscriptions();
  }

  private prepareTimeTrackingSubscriptions(): void {
    this.appSubscriptions.push(this.sidebarStorageService.approvedReportsCount.subscribe(             items => this.approvedReportsCount              = items));
    this.appSubscriptions.push(this.sidebarStorageService.openActivityReportsCount.subscribe(         items => this.openActivityReportsCount          = items));
    this.appSubscriptions.push(this.sidebarStorageService.clarificationReportsCount.subscribe(        items => this.clarificationReportsCount         = items));
    this.appSubscriptions.push(this.sidebarStorageService.failedExportActivityReportsCount.subscribe( items => this.failedExportActivityReportsCount  = items));

    this.appSubscriptions.push(this.sidebarStorageService.activePhotoDocumentsCount.subscribe(        items => this.activePhotoDocumentsCount         = items));
    this.appSubscriptions.push(this.sidebarStorageService.unconfirmedEBSCount.subscribe(              items => this.unconfirmedEBSCount               = items));
    this.appSubscriptions.push(this.sidebarStorageService.expiringSoonAssignmentsCount.subscribe(     items => this.expiringSoonAssignmentsCount      = items));

    this.appSubscriptions.push(this.sidebarStorageService.approvedVacationRequestsCount.subscribe(    items => this.approvedVacationRequestsCount     = items));
    this.appSubscriptions.push(this.sidebarStorageService.awaitingVacationRequestsCount.subscribe(    items => this.awaitingVacationRequestsCount     = items));
    this.appSubscriptions.push(this.sidebarStorageService.rejectedVacationRequestsCount.subscribe(    items => this.rejectedVacationRequestsCount     = items));
    this.appSubscriptions.push(this.sidebarStorageService.failedExportVacationRequestsCount.subscribe(items => this.failedExportVacationRequestsCount = items));

    this.appSubscriptions.push(this.sidebarStorageService.awaitingMileageMoneyReportsCount.subscribe( items => this.awaitingMileageMoneyReportsCount  = items));
  }

  private prepareInvoicesSubscriptions(): void {
    this.appSubscriptions.push(this.sidebarStorageService.activeInvoicesCount.subscribe(  items => this.activeInvoicesCount   = items));
    this.appSubscriptions.push(this.sidebarStorageService.archivedInvoicesCount.subscribe(items => this.archivedInvoicesCount = items));
  }

  getReportsCount(navItem: SidebarRouteExtended): number | string {
    if      (navItem.counter)                    return this.getCounterNumber(navItem.counter);
    else if (navItem.identifier === 'dashboard') return this.collectChildCounters(navItem.children);
  }

  private getCounterNumber(counter: string): number | string {
    switch (counter) {
      case 'approved':
       return this.approvedReportsCount;
      case 'open':
        return this.openActivityReportsCount;
      case 'rejected':
        return this.clarificationReportsCount;
      case 'photo':
        return this.activePhotoDocumentsCount;
      case 'ebs':
        return this.expiringSoonAssignmentsCount || this.unconfirmedEBSCount;
      case 'failed_export':
        return this.failedExportActivityReportsCount || this.failedExportVacationRequestsCount ? `${+this.failedExportActivityReportsCount}+${+this.failedExportVacationRequestsCount}` : null;
      case 'active':
        return this.activeInvoicesCount;
      case 'archived':
        return this.archivedInvoicesCount;
      case 'vr-approved':
        return this.approvedVacationRequestsCount+this.awaitingVacationRequestsCount+this.rejectedVacationRequestsCount;
      case 'vr-awaiting':
        return this.approvedVacationRequestsCount+this.awaitingVacationRequestsCount+this.rejectedVacationRequestsCount;
      case 'mm-open':
        return this.awaitingMileageMoneyReportsCount;
      default:
        return 0;
    };
  }

  private collectChildCounters(children: SidebarRoute[]): number {
    return children.reduce((sum, val) => sum += +!!this.getCounterNumber(val.counter), 0);
  }

  changeDashboard(navItem: SidebarRouteExtended): any {
    if (navItem.identifier === 'logout') return this.authService.signOut$();
    if (navItem.externalPath) return window.open(navItem.externalPath, '_blank');
    if (navItem.path) this.router.navigateByUrl(`${this.sessionStorageService.activeAppValue}/${navItem.path}`);

    this.reloadList(navItem);
    this.handleArchive(navItem);

    if (window.innerWidth < 1280) this.closeSidebar();
  }

  private reloadList(navItem: SidebarRouteExtended): void {
    if (this.sessionStorageService.isTimeTracking && (navItem.counter || navItem.identifier === 'dashboard')) {
      this.notificationService.wait();
      if (navItem.identifier === 'ar-approved' || 
          navItem.identifier === 'ar-open'     || 
          navItem.identifier === 'ar-rejected' ||
          navItem.identifier === 'dashboard')  this.activityReportsService.forceReload();

      if (navItem.identifier === 'vr-list')    this.vacationRequestListService.forceReload();
      if (navItem.identifier === 'photo-doc')  this.photoDocumentService.forceReload();
      if (navItem.identifier === 'failed-erp') this.failedErpService.forceReload();
      if (navItem.identifier === 'ebs-list')   this.assignmentService.forceReload();
      if (navItem.identifier === 'mm-list')    this.mileageMoneyListService.forceReload();
    } else if (this.sessionStorageService.isInvoices) {
      this.notificationService.wait();
      this.invoicesService.forceReload(navItem.filter);
    }
  }

  private handleArchive(navItem: SidebarRouteExtended): void {
    if (this.sessionStorageService.isTimeTracking && navItem.identifier.includes('archive')) {
      let tab = navItem.path.includes('search-archiv?tab=') ? navItem.path.split('?tab=')[1] : 'ar';
      this.sessionStorageService.changeArchiveTogglers(tab);
      this.sessionStorageService.changeAndResetActiveTab(`archive-${tab}`);
    } else this.sessionStorageService.changeAndResetActiveTab(navItem.identifier);
  }

  closeSidebar(): void {
    if (!$('#portal-sidebar').hasClass('mini-sidebar')) $('#portal-sidebar').addClass('mini-sidebar');
    if ($('#portal-sidebar').hasClass('mini-sidebar-xs')) $('#portal-sidebar').removeClass('mini-sidebar-xs');
    this.sidebarStorageService.changeSidebarState('mini');
  }

  private toggleSidebar(): void {
    if ($('#portal-sidebar').hasClass('mini-sidebar-xs')) $('#portal-sidebar').toggleClass('mini-sidebar-xs');
    else $('#portal-sidebar').toggleClass('mini-sidebar');
    this.sidebarStorageService.toggleSidebarState();
  }

  openDebug(): void {
    if (this.debugMode) {
      this.debugCounter--;
      if (this.debugCounter === 0) this.debugMode = false;
    } else {
      this.debugCounter++;
      if (this.debugCounter === 10) this.debugMode = true;
    }
  }

  openToast(e) {
    e.stopPropagation();
    this.toasterService.openPreferences();
  }

}

import { Injectable } from '@angular/core';
import { DailyReport, DailyReportPause, ActivityReportExtended, MileageMoneyBasicDaily, StandaloneMileageMoney, ActivityReportMileageMoneyExtended } from '@shared/factories';
import { LegalError, DailyError, TimeError, EndTimeError, StartTimeError, LegalTimeError } from '@shared/models';

const DAY = 24 * 60 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})

export class ValidationService {
  technicalValidation: any;
  legalValidation:     any;
  mileageValidation:   any;
  constructor ( ) {
    this.technicalValidation = {
      workingHoursOutOfWorkingRange: (tt: DailyReport, ar: ActivityReportExtended): void => {
        let error;
        if (tt.startTime < ar.start_date ||
            tt.endTime < ar.start_date ||
            tt.startTime > ar.end_date ||
            tt.startTime.getTime() > +ar.assignment.ends_at ||
            tt.endTime.getTime() > (+ar.assignment.ends_at + DAY) ||
            tt.endTime.getTime() > (+ar.end_date + DAY)) error = new TimeError('Die Tagesberichte müssen alle innerhalb der ausgewählten Kalenderwoche sein.', tt.id);
        if (error && (ar.split_child_id || ar.split_parent_id)) error = new DailyError('Die Tagesberichte müssen innerhalb der ausgewählten Kalenderwoche und des ausgewählten Monats liegen.', tt.id);
        if (error) tt.errors.push(error);
      },
      pauseOutOfWorkingHours: (tt: DailyReport): void => {
        tt.activePauses.forEach((p: DailyReportPause, p_index): void => {
          if (p.pauseStart < tt.startTime ||
              p.pauseStart > tt.endTime ||
              p.pauseEnd < tt.startTime ||
              p.pauseEnd > tt.endTime ) p.errors.push(new TimeError('Ihre Pausen müssen innerhalb Ihrer Arbeitszeit liegen.', tt.id, p.id));
        });
      },
      pauseInsideOtherPauses: (tt: DailyReport): void => {
        tt.activePauses.forEach((pause, index) => {
          let err = tt.activePauses.filter((item, kindex) => kindex != index)
          .find(item =>
            (item.pauseStart.getTime() <= pause.pauseStart.getTime() && item.pauseEnd.getTime() > pause.pauseStart.getTime()) ||
            (item.pauseStart < pause.pauseEnd && item.pauseEnd > pause.pauseEnd) ||
            (pause.pauseStart < item.pauseStart && pause.pauseEnd > item.pauseEnd)
          );
          if (err) pause.errors.push(new TimeError('Ihre Pausen können nicht mit einer anderen Pause überlappen.', tt.id, pause.id));
        });
        // tt.pauses.reduce((sum, val) => {
        //   if (sum) {
        //     if (val.pauseStart >= sum.pauseStart && val.pauseStart < sum.pauseEnd ) {
        //       val.errors.push(new TimeError('Ihre Pausen können nicht mit einer anderen Pause überlappen.'));
        //     }
        //   }
        //   return val;
        // }, new DailyReportPause({start: null, end: null}));
      },
      maxThreePausesPerTimeFrame: (tt: DailyReport): void => {
        tt.activePauses.forEach((pause, index) => {
          if (index > 2) pause.errors.push(new TimeError('Dieser Tag hat zu viele Pausen. Es sind max. 3 erlaubt.', tt.id, pause.id));
        });
      },
      pauseEndBeforeStart: (tt: DailyReport): void => {
        tt.activePauses.forEach((p: DailyReportPause): void => {
          if (p.pauseEnd < p.pauseStart) p.errors.push(new TimeError('Die Startzeit der Pause kann nicht nach der Endzeit der Pause liegen.', tt.id, p.id));
        });
      },
      zeroMinuteTimes: (tt: DailyReport): void => {
        if (tt.totalDuration <= 0) tt.errors.push(new TimeError('Der Tätigkeitsnachweis enthält keine Arbeitszeiten.', tt.id))
      },
      zeroMinutePause: (tt: DailyReport): void => {
        tt.activePauses.forEach((p) => {
          if (p.pauseEnd.getTime() === p.pauseStart.getTime()) p.errors.push(new TimeError('Pausenzeit darf nicht leer sein.', tt.id, p.id));
        });
      },
      sameDayDaylies: (tt: DailyReport, ar: ActivityReportExtended, index: number): void => {
        let sameDay = ar.activeTimeFrames.filter((tf, tindex) => tindex !== index).find(tf => tf.startTime.getDate() === tt.startTime.getDate());
        if (sameDay) tt.errors.push(new DailyError('Jeder Wochentag darf nur einmalig existieren im Tätigkeitsnachweis.', tt.id));
      },
      daysInFuture: (tt: DailyReport): void => {
        let now   = new Date(  new Date().getFullYear(),   new Date().getMonth(),   new Date().getDate(), 0 , 0, 0);
        let start = new Date(tt.startTime.getFullYear(), tt.startTime.getMonth(), tt.startTime.getDate(), 0 , 0, 0);
        if (start.getTime() > now.getTime()) tt.errors.push(new DailyError('Sie können keine Arbeitszeiten hinzufügen, die in der Zukunft liegen.', tt.id));
      },
      ocrStartInvalid: (tt: DailyReport): void => {
        if (tt.ocrStartInvalid) tt.errors.push(new StartTimeError('Sie können keine Arbeitszeiten hinzufügen, die in der Zukunft liegen.', tt.id));
      },
      ocrEndInvalid: (tt: DailyReport): void => {
        if (tt.ocrEndInvalid) tt.errors.push(new EndTimeError('Sie können keine Arbeitszeiten hinzufügen, die in der Zukunft liegen.', tt.id));
      },
      daily_timeslot: (tt: DailyReport, ar: ActivityReportExtended, index: number) => {
        if (index !== 0 && ar.activeTimeFrames.length > 1 ) { // first tt considered always valid
          let timeslotBooked = ar.activeTimeFrames.filter((tf, tindex) => tindex !== index).filter(item => 
            tt.startTime.getDate() === item.startTime.getDate() ||
            tt.endTime.getDate() === item.startTime.getDate() ||
            tt.endTime.getDate() === item.endTime.getDate()
          ).find(item => (tt.startTime.getTime() <= item.startTime.getTime() && tt.endTime.getTime() >= item.endTime.getTime()) ||
            (tt.startTime.getTime() > item.startTime.getTime() && tt.endTime.getTime() < item.endTime.getTime()) ||
            (tt.startTime.getTime() < item.startTime.getTime() && tt.endTime.getTime() > item.startTime.getTime()) ||
            (tt.startTime.getTime() < item.endTime.getTime() && tt.endTime.getTime() > item.endTime.getTime())
          );
          if (timeslotBooked) return tt.errors.push(new TimeError('Die angegebenen Arbeitszeiten überschneiden sich. Bitte korrigieren Sie Ihre Eingaben.', tt.id));
        }
      },
      preventMonthBreak: (tt: DailyReport, ar: ActivityReportExtended, index: number) => {
        if (index && ar.id && !ar.attachment_url && (ar.split_child_id || ar.split_parent_id)) {
          let validMonth   = ar.activeTimeFrames[0].startTime.getMonth();
          let currentMonth = tt.startTime.getMonth();
          if (validMonth !== currentMonth) return tt.errors.push(new DailyError('Die Tagesberichte müssen innerhalb der ausgewählten Kalenderwoche und des ausgewählten Monats liegen.', tt.id));
        }
      }
    };

    this.legalValidation = {
      maxWorkingHours: (tt: DailyReport): void => {
        let maxWorkingHours = 10 * 60 * 60 * 1000; // max 10 hours per day
        if (tt.totalDurationExcludingPauses > maxWorkingHours) tt.errors.push(new LegalTimeError("Ihre Arbeitszeit ist länger als 10 Stunden.", tt.id));
      },
      singlePauseDuration: (tt: DailyReport): void => {
        tt.activePauses.forEach((p: DailyReportPause): void => {
          let pauseDuration = (+p.pauseEnd - +p.pauseStart) / 60000;
          if (pauseDuration < 15) p.errors.push(new LegalError('Eine Pause muss mindestens 15 Minuten betragen.', tt.id, p.id));
        });
      },
      totalPausesTime: (tt: DailyReport): void => {
        let nineHoursString = 9 * 60 * 60 * 1000;
        let sixHoursString = 6 * 60 * 60 * 1000;
        let shortPause = 30 * 60 * 1000;
        let longPause = 45 * 60 * 1000;

        if (tt.totalDurationExcludingPauses > nineHoursString) {
          if (tt.pausesDuration < longPause) {
            tt.errors.push(new LegalTimeError('Ihre Arbeitszeit beträgt mehr als 9 Stunden. Sie benötigen eine Pause von mindestens 45 Minuten.', tt.id));
          }
        } else if (tt.totalDurationExcludingPauses > sixHoursString) {
          if (tt.pausesDuration < shortPause) {
            tt.errors.push(new LegalTimeError('Ihre Arbeitszeit beträgt mehr als 6 Stunden. Sie benötigen eine Pause von mindestens 30 Minuten.', tt.id));
          }
        }
      }

    };

    this.mileageValidation = {
      addressMissing: (mileage: MileageMoneyBasicDaily): void => {
        if (!mileage.externalEmployeeAddress || !mileage.assignmentAddress) mileage.errors.push({ message: 'Eine Adresse ist erforderlich.' });
      },
      distance_missing: (mileage: MileageMoneyBasicDaily): void => {
        if (!mileage.amountOfKm) mileage.errors.push({ message: 'Eine Kilometerbetrag ist erforderlich.' });
      }
    };
  }

  validate(ar: ActivityReportExtended): void {
    ar.activeTimeFrames.forEach((tf, index) => {
      for (let key in this.technicalValidation) { this.technicalValidation[key](tf, ar, index);  }
      for (let key in this.legalValidation)     { this.legalValidation[key](tf, ar); }
    });
  }

  validateMM(mileageReport: ActivityReportMileageMoneyExtended | StandaloneMileageMoney): void {
    if (!mileageReport.activeWorkGroups || !mileageReport.activeWorkGroups.length) mileageReport.errors.push({ message: 'Kilometergeldbericht kann nicht leer sein.' });
    else mileageReport.allWorkReports.forEach((mm, index) => {
      for (let key in this.mileageValidation) { this.mileageValidation[key](mm);  }
    });
  }

  checkIfEmail(email: string): boolean {
    let reg = new RegExp('^[a-zA-Z0-9_+-.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$|^\d+$');
    return reg.test(email.trim());
  }
}


import { Component, Input, Output, EventEmitter, SimpleChanges, HostListener } from '@angular/core';

@Component({
  selector: 'editable-input',
  templateUrl: 'editable-input.component.html',
  styleUrls: ['./editable-input.component.sass'],
  host: { class: 'am-flex-align gap-xs' }
})
export class EditableInputComponent {
  @Input() editBox: string;
  @Input() index: string;
  @Input() type: string;
  @Input() maxValue: string;
  @Input() value: string | number;
  @Input() valueType: string;
  @Input() postValue: string;
  @Input() showEdit: boolean;
  @Output() onValueChange = new EventEmitter<any>();
  @Output() toggleEditBox = new EventEmitter<any>();

  tempValue: string | number;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.value) this.resetInput(changes.value.currentValue);
    if (changes && changes.editBox) this.editBox = changes.editBox.currentValue;
    if (changes && changes.showEdit && changes.showEdit.currentValue === false) this.value = this.tempValue;
  }

  onInput(e) {
    if (this.type === 'number') {
      let temp = e.target.value.toString().split('.');
      if (this.maxValue && e.target.value > this.maxValue) e.target.value = this.maxValue;
      else if (temp[1] !== undefined && temp[1] !== null) e.target.value = [temp[0], temp[1].substring(0, 2)].join('.');
    }
  }

  onBlur(e): void {
    this.onValueChange.emit(this.formatByTypeForPreview(e.target.value));
    this.toggleEditBox.emit(null);
  }

  toggleEditableInput(index: string): void {
    this.editBox = this.editBox === index ? null : index;
    if (this.editBox !== null) {
      setTimeout(() => {
        let el: HTMLInputElement = document.querySelector(`#edit-field-${this.editBox}`);
        el.value = this.formatByTypeForInput(this.value);
        if (this.type === 'number') {
          el.step = '0.1';
          el.min = "0";
        }
        el.focus();
        if (window.document['documentMode']) el.value = '';
      });
    }
    this.toggleEditBox.emit(this.editBox);
  }

  private resetInput(value): void {
    this.tempValue = value;
    this.value = value;
  }

  formatByTypeForInput(value): string {
    switch (this.type) {
      case 'text':
        return value + '';
      case 'number':
        return isNaN(value) ? +value.replace(',', '.') : value;
      default:
       return value;
    }
  }

  formatByTypeForPreview(value): string | number {
    switch (this.valueType) {
      case 'currency':
        return value !== null && value !== undefined && (value === '0' || value === '0,00' || +(value+'').replace(',', '.')) 
               ? isNaN(value)
                 ? (+value.replace(',', '.')).toFixed(2).replace('.', ',')
                 : new Number(value).toFixed(2).replace('.', ',')
               : '-';
      case 'distance':
        return isNaN(value) ? (+value.replace(',', '.')).toFixed(1).replace('.', ',') : new Number(value).toFixed(1).replace('.', ',');
      case 'text':
        return value + '';
      case 'number':
        return +value;
      default:
       return value;
    }
  }

}

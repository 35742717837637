// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#portal-sidebar .sidebar-overflow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.3019607843);
  z-index: 1;
}
#portal-sidebar.mini-sidebar-xs .sidebar-overflow {
  display: block;
}

#portal-sidebar .sidebar-wrapper {
  z-index: 2;
}
@media (max-width: 768px) {
  #portal-sidebar .sidebar-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  #portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-wrapper {
    left: -300px;
  }
}
@media (max-width: 450px) {
  #portal-sidebar .sidebar-wrapper {
    width: 215px;
  }
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-wrapper {
  width: 75px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

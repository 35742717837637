import { ActivityReportMileageMoneyDailyModel, StandaloneMileageMoneyDailyModel, ValidationError } from "@shared/models";
import { ActivityReportMileageMoneyDaily, StandaloneMileageMoneyDaily } from "@shared/factories";

export class MileageMoneyWorkGroup {
  workDays: (ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily)[];
  errors:    ValidationError[];
  constructor(data: ActivityReportMileageMoneyDailyModel[] | StandaloneMileageMoneyDailyModel[]) {}

  get activeWorkDays(): (ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily)[] {
    return this.workDays?.filter(m => !m.deleted) || [];
  }

  get totalKm():  number { return this.activeWorkDays?.reduce((sum: number, val: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily) => { sum = sum + val.totalAmountOfKm; return sum }, 0) || 0; }
  get totalSum(): number { return this.activeWorkDays?.reduce((sum: number, val: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily) => { sum = sum + val.calculatedSum;   return sum }, 0) || 0; }
}

export class ActivityReportMileageMoneyWorkGroup extends MileageMoneyWorkGroup {
  workDays: ActivityReportMileageMoneyDaily[];
  constructor(work_days: ActivityReportMileageMoneyDailyModel[]) {
    super(work_days);
    this.workDays = work_days.length ? work_days.map(wd => new ActivityReportMileageMoneyDaily(wd)) : null;
  }
}

export class StandaloneMileageMoneyWorkGroup extends MileageMoneyWorkGroup {
  workDays: StandaloneMileageMoneyDaily[];
  constructor(work_days: StandaloneMileageMoneyDailyModel[]) {
    super(work_days);
    this.workDays = work_days.length ? work_days.map(wd => new StandaloneMileageMoneyDaily(wd)) : null;
  }
}
